






























































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Core
} from "@/store/core";
import {
    Auth
} from "@/store/auth";
import {
    User
} from "@/store/user";
import _ from "lodash";
@Component({
    components: {},
})
export default class Home extends Vue {
    private form: any = {
        type: "",
    };
    chooseYear: any = 1;
    chooseAgency: any = 1;

    private agency: any = null;
    private top: any = [];
    private down: any = [];
    private years: any = [];
    private lists: any = [];
    private user: any = {};
    async created() {
        //  await Auth.reToken();
        this.user = await User.getUser();
        this.agency = await Core.getHttp(`/api/ita/v1/agency/`);
        this.years = await Core.getHttp(`/api/eit/v1/year`);
        this.top = _.orderBy(this.agency, ["count"], ["desc"]);
        this.down = _.orderBy(this.agency, ["count"], ["asc"]);

        this.chooseYear = this.years[this.years.length - 1].id;

        await this.search();
    }

    async addItem(text: string) {
        if (this.form.type == "") {
            this.form.type += text;
        } else {
            this.form.type += "," + text;
        }
    }

    async addItemArray(text: string, index: number) {
        if (this.lists[index].type == "") {
            this.lists[index].type += text;
        } else {
            this.lists[index].type += "," + text;
        }
    }

    prePrint() {
        window.print();
    }

    async search() {
        this.lists = await Core.getHttp(
            `/api/ita/v1/eituser/?year=${this.chooseYear}&agency=${this.user.ext_link.agency}`
        );
        // if(this.chooseYear > 2){
        //       this.lists =  _.map(this.lists,  (element)=> {
        //             return _.extend({}, element, {type: JSON.parse(element.type)});
        //         });
        // }
    }

    async addEit() {
        this.form.year = this.chooseYear;
        this.form.agency = this.user.ext_link.agency;
        // if(this.chooseYear > 2){
        //         this.form.type = JSON.stringify(this.form.type);
        // }

        let save = await Core.postHttp(`/api/ita/v1/eituser/`, this.form);
        if (save.id) {
            alert("เพิ่มข้อมูลสำเร็จแล้ว");
            //   this.lists = await Core.getHttp(`/api/ita/v1/eituser/?year=${this.chooseYear}&agency=${this.user.ext_link.agency}`)
            await this.search();
            this.form = {};
        }
    }

    async editEit(item: any) {
        let form = item;
        //   if(this.chooseYear > 2){
        //        form.type = JSON.stringify(item.type);
        // }
        let save = await Core.putHttp(`/api/ita/v1/eituser/${form.id}/`, form);

        if (save.id) {
            alert("แก้ไขข้อมูลสำเร็จแล้ว");
            //   this.lists = await Core.getHttp(`/api/ita/v1/eituser/?year=${this.chooseYear}&agency=${this.user.ext_link.agency}`)
            await this.search();
        }
    }
    async deleteEit(item: any) {
        let confirms = confirm("คุณแน่ใจใช่ไหมที่จะลบข้อมูล");
        if (confirms) {
            let save = await Core.deleteHttp(`/api/ita/v1/eituser/${item.id}/`);
            alert("ลบข้อมูลสำเร็จแล้ว");
            this.lists = await Core.getHttp(
                `/api/ita/v1/eituser/?year=${this.chooseYear}&agency=${this.user.ext_link.agency}`
            );
        }
    }
}
